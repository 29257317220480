import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import cx from "classnames";
import SEO from "components/Seo";
import Footer from "components/Footer/Footer";
import { GuestOptionsBar } from "components/GuestOptionsBar";
import RoomSelectionSearchDrawer from "components/Design/Drawer/RoomSelectionSearchDrawer";
import ButtonFullWidthWithIcon from "components/Design/Button/ButtonFullWidthWithIcon";
import { Navbar } from "components/Navbar";
import {
  RIMBA_JIMBARAN_BALI,
  VILLAS_AYANA_RESORT_BALI,
  ROCKBAR_RESTAURANT,
  JAKARTA,
  AYANA_VILLA_ID,
  AYANA_RESORT_ID,
  AYANA_RIMBA_JIMBARAN_ID,
  AYANA_SEGARA_ID,
  AYANA_KOMODO_ID,
  AYANA_LAKODIA_ID,
} from "store/constants/hotel";
import { Helmet } from "react-helmet";

import "./style.less";
import FontLoad from "components/FontLoad";
import { fetchCart } from "apis/cart";
import SeoUrl from "components/SeoUrl";
import { WhatsAppFloat } from "components/Design/FloatingButton";
import { AyanaLogo } from "components/Logo/AyanaLogo";
import { whatsAppUrl } from "utils/whatsAppUrl";
import { WECHAT_LINK } from "store/constants/link";

export const PropertyThemeClasses = ({ hotel }) => {
  return (
    <Helmet>
      <body
        className={cx("", {
          "rimba-theme": hotel.name === RIMBA_JIMBARAN_BALI,
          "villas-theme": hotel.name === VILLAS_AYANA_RESORT_BALI,
        })}
      />
    </Helmet>
  );
};

const Layout = ({
  seoTitle,
  seoTags,
  banner,
  showBanner,
  children,
  destination,
  destinationCards,
  navElement,
  contactOnMobile,
  contactOnDesktop,
  showStickyOnMobile,
  bookUsingWhatsapp,
  seoUrl,
  navigations,
  hotel,
  socialMedia,
  socialMediaLabel,
  supportDrawer,
  hotelPropertyBali,
  hotelTheme,
  restaurant,
  minimumStay,
  bookingCode,
  whatsAppLink: whatsAppLinkFromProps,
  homepage,
  isOffer,
  stayPeriod,
  isPackage,
  lvxLogo,
}) => {
  const [cartCount, setCartCount] = useState(0);
  const { language } = useI18next();
  const { t } = useTranslation(["homepage", "offersLanding"]);
  useEffect(() => {
    async function cartItems() {
      try {
        const cart = await fetchCart();
        setCartCount(cart.data.items.length);
      } catch (error) {}
    }
    cartItems();
  }, []);

  const ColorLogo = () => {
    return <AyanaLogo image={hotel.logo_horizontal[0]} />;
  };

  const statusWaDisbled = [AYANA_KOMODO_ID, AYANA_LAKODIA_ID].map((hotelId) =>
    hotelPropertyBali?.find(({ id }) => hotelId === id)
  );

  const whatsappLink =
    whatsAppLinkFromProps ||
    whatsAppUrl(hotel.whatsapp_contact, hotel.custom_whatsapp_message);

  let contactIconLink = whatsappLink;

  if (language === "zh") {
    contactIconLink = WECHAT_LINK;
  }

  const isJakarta = destination === t("Jakarta");
  const reachUsLink = language === "zh" ? WECHAT_LINK : whatsappLink;
  const iconClassName = language === "zh" ? "" : "text-green-wa-dark";

  let iconName = language === "zh" ? "weibo" : "whatsapp";
  if (isJakarta || Boolean(statusWaDisbled)) {
    iconName = null;
  }

  const hotelPropertyBaliSorted =
    hotelPropertyBali &&
    [
      AYANA_VILLA_ID,
      AYANA_SEGARA_ID,
      AYANA_RESORT_ID,
      AYANA_RIMBA_JIMBARAN_ID,
    ].map((hotelId) => hotelPropertyBali?.find(({ id }) => hotelId === id));

  return (
    <>
      <SEO
        title={seoTags.meta_title}
        description={seoTags.meta_description}
        favicon={hotel.favicon.cdnURL}
      />
      {(language === "zh" || language === "ja") && <FontLoad />}

      <SeoUrl url={seoTags.url} />
      <PropertyThemeClasses hotel={hotelTheme || hotel} />
      <div
        className={cx("antialiased", {
          /* helps in wrapping words at spaces for Japanese language */
          "font-ja wrap-word": language === "ja",
          "font-zh": language === "zh",
          "font-ko": language === "ko",
        })}>
        <div className="hidden lg:block lg:sticky lg:top-0 lg:z-100">
          <GuestOptionsBar
            destinationCards={destinationCards}
            hotel={hotel}
            cartCount={cartCount}
            navigationHotel={navigations.hotel}
            hotelPropertyBali={hotelPropertyBaliSorted}
          />
        </div>
        <div className="antialiased">
          <Navbar
            destination={destination}
            banner={banner}
            showBanner={showBanner}
            destinationCards={destinationCards}
            contactOnDesktop={contactOnDesktop}
            cartCount={cartCount}
            navigations={navigations}
            hotel={hotel}
            supportDrawer={supportDrawer}
            supportReachUsLink={reachUsLink}
            colorLogo={<ColorLogo />}
            hotelPropertyBali={hotelPropertyBaliSorted}
            restaurant={restaurant}
            minimumStay={minimumStay}
            stayPeriod={stayPeriod}
            bookingCode={bookingCode}
            whatsappLink={Boolean(statusWaDisbled) ? "" : whatsappLink}
            homepage={Boolean(homepage)}
            isPackage={isPackage}
            location={isJakarta && JAKARTA}
          />
          {banner && <div>{banner}</div>}
          <section className="overflow-x-hidden antialiased ">
            {children}
          </section>
          <Footer
            destinationCards={destinationCards}
            socialMedia={socialMedia}
            socialMediaLabel={socialMediaLabel}
            navigations={navigations}
            hotel={hotel}
            lvxLogo={lvxLogo}
          />
          {!isJakarta ||
            (!Boolean(statusWaDisbled) && (
              <WhatsAppFloat link={whatsappLink} />
            ))}
          <RoomSelectionSearchDrawer
            isPackage={isPackage}
            stayPeriod={stayPeriod}
            minimumStay={minimumStay}
            bookingCode={bookingCode}
            hotelCode={hotel.hotel_code}
            location={hotel.location}
            destination={destination}
            ayanaLogo={navElement === "Room" && <ColorLogo />}
            cta={(onClick, sticky) => (
              <div
                className={cx(
                  "fixed lg:hidden bottom-0 w-full z-20 transition-all duration-500",
                  {
                    "opacity-0 invisible": !sticky,
                    // "opacity-100 visible": sticky,
                  }
                )}>
                {showStickyOnMobile &&
                  (contactOnMobile ? (
                    <ButtonFullWidthWithIcon
                      label={contactOnMobile?.label || t("Contact Us")}
                      iconName={Boolean(statusWaDisbled) ? "" : iconName}
                      iconClassName={iconClassName}
                      iconLink={contactIconLink}
                      buttonLink={contactOnMobile?.url}
                    />
                  ) : (
                    restaurant !== ROCKBAR_RESTAURANT && (
                      <ButtonFullWidthWithIcon
                        label={t("View Rates")}
                        buttonLink={bookUsingWhatsapp && contactIconLink}
                        onClick={!bookUsingWhatsapp && onClick}
                        iconName={Boolean(statusWaDisbled) ? "" : iconName}
                        iconClassName={iconClassName}
                        iconLink={contactIconLink}
                      />
                    )
                  ))}
              </div>
            )}
          />
        </div>
      </div>
    </>
  );
};

Layout.defaultProps = {
  title: "Ayana Komodo",
  seoTags: {},
  contactOnMobile: false,
  bookUsingWhatsapp: false,
  showStickyOnMobile: true,
};

Layout.propTypes = {
  seoTitle: PropTypes.string,
  seoTags: PropTypes.object,
  banner: PropTypes.element,
  children: PropTypes.element,
  showStickyOnMobile: PropTypes.bool,
};

export default Layout;
