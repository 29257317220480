import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

export const ButtonMedium = ({ label, type, icon, ...rest }) => {
  return (
    <button
      className={cx("btn text-12-22 py-8 px-20 rounded-sm", {
        "btn-blue md:w-auto": type === "primary",
        "btn-outline-gold": type === "secondary",
        "btn-white md:w-auto": type === "white",
        "flex items-center btn-blue md:w-auto": type === "primaryWithIcon",
      })}
      {...rest}>
      {icon && <span className="pr-5">{icon}</span>} {label}
    </button>
  );
};

ButtonMedium.defaultProps = {
  type: "primary",
  icon: "",
};

ButtonMedium.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.string,
};
