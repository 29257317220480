import React from "react";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import Layout from "components/Layout/Layout";
import { ButtonMedium } from "components/Design/Button/ButtonMedium";
import PageContext from "contexts/PageContext";
import { AYANA_BALI } from "store/constants/hotel";
import { generateSupportURL } from "utils/generateSupportURL";
import { AYANA_BALI_WHATSAPP_LINK } from "store/constants/socmedLink";
import { transformObject } from "utils/transformv4";

const BaliThankYou = ({ data }) => {
  const { t } = useTranslation("homepage");
  const {
    cms,
    allNavigationMenuJson: {
      nodes: [navigations],
    },
    allSocialMediaJson: {
      nodes: [socialMediaList],
    },
  } = data;

  const {
    sections: [destinations],
    hotelsByLocale: [hotel],
    supportDrawer: [supportDrawer],
    hotelPropertyBali,
  } = transformObject(cms);

  const dataContact = {
    url: generateSupportURL("bali"),
    label: t("Contact Us"),
  };

  const supportDrawerData = {
    ...supportDrawer,
    hotels: hotelPropertyBali,
    hotel: hotel,
  };

  return (
    <PageContext.Provider value={{ location: AYANA_BALI }}>
      <Layout
        whatsAppLink={AYANA_BALI_WHATSAPP_LINK}
        destination={t("Bali")}
        showBanner={true}
        destinationCards={destinations.images}
        navigations={navigations}
        hotel={{ ...hotel, ...hotel.destinations[0] }}
        socialMedia={socialMediaList}
        socialMediaLabel={t("Connect with AYANA Bali")}
        contactOnMobile={dataContact}
        contactOnDesktop={dataContact}
        supportDrawer={supportDrawerData}
        hotelPropertyBali={hotelPropertyBali}>
        <div className="py-40 md:py-60 text-center">
          <h1 className="text-m-h2 md:text-h1 font-semibold text-gray-text pb-12 md:pb-16">
            {t("Thank You")}
          </h1>
          <p className="w-9/12 md:w-2/6 m-auto text-gray-text text-12-20 md:text-16-24 pb-16 md:pb-24">
            {t("processingRequest")}
          </p>
          <Link to="/bali/support">
            <ButtonMedium type="primary" label={t("backToSupport")} />
          </Link>
        </div>
      </Layout>
    </PageContext.Provider>
  );
};

export const query = graphql`
  query BaliThankYou($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allNavigationMenuJson(filter: { hotel: { eq: "AYANA Bali" } }) {
      ...LocalNavigation
    }
    allSocialMediaJson(filter: { hotel: { eq: "AYANA Bali" } }) {
      nodes {
        hotel
        socmed {
          link {
            en
            ja
          }
          iconName
        }
      }
    }
    cms {
      sections(filters: { name: { eq: "Bali_Footer_Destination" } }) {
        ...NavSections
      }
      supportDrawer: sections(
        filters: { name: { eq: "Bali_Support_Drawer" } }
      ) {
        ...NavSections
      }
      hotelsByLocale(language: $language, where: { id: 2 }) {
        data {
          id
          attributes {
            ...NavHotel
          }
        }
      }
      hotelPropertyBali: hotelsByLocale(
        language: $language
        where: { id: [1, 2, 3, 9] }
        sort: "id:DESC"
      ) {
        data {
          id
          attributes {
            name
            title
            logo {
              ...CmsUploadFileEntityResponse
            }
            offices(sort: "sequence") {
              ...CmsOffices_v4
            }
          }
        }
      }
    }
  }
`;
export default BaliThankYou;
