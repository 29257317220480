import React from "react";
import { Helmet } from "react-helmet";
import { useI18next } from "gatsby-plugin-react-i18next";

function SeoUrl({ url }) {
  const { languages, language, defaultLanguage, siteUrl = "" } = useI18next();

  const createUrlWithLang = (lng, seoUrl) => {
    const url = `${siteUrl}${
      lng === defaultLanguage ? "" : `/${lng}`
    }${seoUrl}`;
    return url.endsWith("/") ? url : `${url}/`;
  };

  return (
    <Helmet>
      <html lang={language} />
      <link rel="canonical" href={createUrlWithLang(language, url)} />
      {languages.map((lng) => (
        <link
          rel="alternate"
          key={lng}
          href={createUrlWithLang(lng, url)}
          hrefLang={lng}
        />
      ))}
      {/* adding a fallback page for unmatched languages */}
      <link
        rel="alternate"
        href={createUrlWithLang(defaultLanguage, url)}
        hrefLang="x-default"
      />
    </Helmet>
  );
}

export default SeoUrl;
