import request from ".";

export const addItemToCart = async (params) => {
  const response = await request.post("/api/v1/cart/items.json", {
    cart_item: params,
  });

  return response.data;
};

export const fetchCart = async () => {
  const response = await request.get("/api/v1/cart.json");
  return response.data;
};

export const removeItemFromCart = async ({ cartItemId }) => {
  const response = await request.delete(
    `/api/v1/cart/items/${cartItemId}.json`
  );

  return response.data;
};
